import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { PolicyAction } from '@/types';
import type { CurrentUserQuery } from '@/gql/graphql';

type CurrentUserAccess = NonNullable<CurrentUserQuery['currentUser']>['access'];
type CurrentUserPolicies = NonNullable<CurrentUserAccess>['accessPolicies'];
type CurrentUserStatements = NonNullable<
    NonNullable<CurrentUserPolicies>[0]
>['statement'];
type CurrentUserStatement = NonNullable<CurrentUserStatements>[0];

interface PolicyStatement {
    resource: string;
    action: string[];
    effect: string;
}

class LRN {
    constructor(
        public account: string,
        public portfolio: string,
        public resourceType: string,
        public resource: string,
        public relationshipResource?: string
    ) {}

    toString() {
        let lrn = [
            'lrn',
            this.account,
            this.portfolio,
            this.resourceType,
            this.resource,
        ].join(':');

        if (this.relationshipResource) {
            lrn += `/${this.relationshipResource}`;
        }

        return lrn;
    }

    static fromString(lrn: string) {
        if (!lrn.startsWith('lrn:')) {
            throw new Error(`LRN must start with "lrn":${lrn}`);
        }

        const [lrnComponent, relationshipResource = undefined] = lrn.split('/');
        const lrnComponents = lrnComponent.split(':');

        if (lrnComponents.length !== 5) {
            throw new Error(`Wrong number of components:${lrn}`);
        }

        const [, account, portfolio, resourceType, resource] = lrnComponents;

        return new LRN(
            account,
            portfolio,
            resourceType,
            resource,
            relationshipResource
        );
    }
}
/**
 * Extract correct resourceType for complex PolicyActions
 * project_convert:__ should return project, not project_convert
 */
function extractResourceType(action: PolicyAction) {
    const [firstPart, secondPart] = action.split(':');
    return secondPart?.includes('__') ? firstPart.split('_')[0] : firstPart;
}

class Policies {
    private policyStatements: PolicyStatement[] = [];

    constructor(access?: CurrentUserAccess) {
        this.setPolicyStatements(access);
    }

    setPolicyStatements(access?: CurrentUserAccess) {
        this.addRolePolicies(access);
        this.addAccessPolicies(access);
    }

    getPolicyStatements() {
        return this.policyStatements;
    }

    addRolePolicies(access?: CurrentUserAccess) {
        access?.roles?.forEach((role) => {
            this.addPolicyStatements(role?.policies);
        });
    }

    restorePolicies(policies: { policyStatements: PolicyStatement[] }) {
        this.policyStatements = policies.policyStatements;
    }

    addAccessPolicies(access?: CurrentUserAccess) {
        this.addPolicyStatements(access?.accessPolicies);
    }

    addPolicyStatements(policies?: CurrentUserPolicies) {
        policies?.forEach((policy) => {
            const statements =
                policy?.statement?.filter(this.isValidStatement) ?? [];

            this.policyStatements = [...this.policyStatements, ...statements];
        });
    }

    isValidStatement(
        statement: CurrentUserStatement
    ): statement is PolicyStatement {
        return (
            statement !== null &&
            !!statement.resource &&
            !!statement.action &&
            !!statement.effect
        );
    }

    /**
     * Check if policy action exists, and is allowed.
     *
     * @example
     *
     * ```ts
     * hasPolicyAction('drawing', 'drawing:execute', '*', '*', '*');
     * ```
     *
     * @param resourceType - The resource type. (e.g. `drawing`)
     * @param action - The policy action. (e.g. `drawing:execute`)
     * @param resource - A specific entity UUID, or a wildcard (`*`) for all.
     * @param account - A specific account UUID, or a wildcard (`*`) for all.
     * @param portfolio - A specific portfolio UUID, or a wildcard (`*`) for all.
     *
     * @returns Boolean indicating if policy action exists, and is allowed.
     */
    hasPolicyAction(
        resourceType: string,
        action: PolicyAction,
        resource = '*',
        account: string | null = '*',
        portfolio: string | null = '*'
    ) {
        let policyStatements = this.findAllStatementsByAccountAndPortfolio(
            account,
            portfolio
        );
        policyStatements = this.filterStatementsByResourceType(
            policyStatements,
            resourceType
        );

        for (const statement of policyStatements) {
            const lrn = LRN.fromString(statement.resource);
            let statementResource = lrn.resource;

            // If 'relationshipResource' is set, do not check parent 'resource'
            if (lrn.relationshipResource) {
                statementResource = '*';
            }

            if (
                ['*', resource].includes(statementResource) &&
                this.statementHasAction(statement, action)
            ) {
                // Deny immediately
                if (statement.effect === 'deny') {
                    return false;
                }

                return true;
            }
        }

        return false;
    }

    findAllStatementsByAccountAndPortfolio(
        account: string | null,
        portfolio: string | null
    ) {
        const statements: PolicyStatement[] = [];

        for (const statement of this.policyStatements) {
            const lrn = LRN.fromString(statement.resource);

            if (
                ([account, '*'].includes(lrn.account) || account === null) &&
                ([portfolio, '*'].includes(lrn.portfolio) || portfolio === null)
            ) {
                statements.push(statement);
            }
        }

        return statements;
    }

    filterStatementsByResourceType(
        policyStatements: PolicyStatement[],
        resourceType: string
    ) {
        const statements: PolicyStatement[] = [];

        for (const statement of policyStatements) {
            const lrn = LRN.fromString(statement.resource);
            const sResourceType = lrn.relationshipResource || lrn.resourceType;

            if (['*', resourceType].includes(sResourceType)) {
                statements.push(statement);
            }
        }

        return statements;
    }

    statementHasAction(statement: PolicyStatement, action: PolicyAction) {
        const resourceType = extractResourceType(action);

        return (
            statement.action.includes('*') ||
            statement.action.includes(`${resourceType}:*`) ||
            statement.action.includes(action) ||
            // List permission allows read
            statement.action.includes(action.replace('read', 'list'))
        );
    }
}

export const usePolicyStore = defineStore(
    'policy',
    () => {
        const policies = ref<Policies | null>(null);
        const policyStatements = computed(() =>
            policies.value?.getPolicyStatements()
        );

        function setPolicyStatements(access?: CurrentUserAccess) {
            policies.value = new Policies(access);
        }

        function hasPolicyAction(
            action: PolicyAction,
            {
                resource = '*',
                account = '*',
                portfolio = '*',
            }: {
                resource?: string;
                account?: string | null;
                portfolio?: string | null;
            } = {}
        ) {
            const resourceType = extractResourceType(action);

            return !!policies.value?.hasPolicyAction(
                resourceType,
                action,
                resource,
                account,
                portfolio
            );
        }

        function $reset() {
            policies.value = null;
        }

        return {
            policies,
            policyStatements,
            setPolicyStatements,
            hasPolicyAction,
            $reset,
        };
    },
    {
        persist: {
            serializer: {
                deserialize: (value) => {
                    const state = JSON.parse(value);
                    const policies = new Policies();

                    policies.restorePolicies(state.policies);

                    return { ...state, policies };
                },
                serialize: JSON.stringify,
            },
        },
    }
);
