import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { logErrorMessages } from '@vue/apollo-util';
import { getCookie } from '@/utils';
import {
    randomStringMiddleware,
    tokenExpiredErrorHandler,
    unauthorizedErrorHandler,
} from './links';

const graphqlHttpEndpoint =
    import.meta.env.VITE_GRAPHQL_ENDPOINT || 'http://localhost:9050/graphql';

const cache = new InMemoryCache({
    resultCaching: false,
});

function getHeaders() {
    const headers: { Authorization?: string; 'random-string'?: string } = {};
    const token = localStorage.getItem('token');
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    let name = '__Secure-random_string';
    if (import.meta.env.MODE === 'development') {
        name = 'random_string';
    }

    headers['random-string'] = getCookie(name);

    return headers;
}

const httpLink = createHttpLink({
    uri: (operation) => {
        return graphqlHttpEndpoint + `?${operation.operationName}`;
    },
    fetch: (uri: string | URL | Request, options?: RequestInit) => {
        const opts = { ...options, headers: getHeaders() };

        return fetch(uri, opts);
    },
    credentials: 'include',
});

const errorLink = onError((error) => {
    if (import.meta.env.VITE_ENV !== 'production') {
        logErrorMessages(error);
    }
});

const link = ApolloLink.from([
    tokenExpiredErrorHandler,
    unauthorizedErrorHandler,
    randomStringMiddleware,
    errorLink.concat(httpLink),
]);

const apolloClient = new ApolloClient({
    link,
    cache,
    credentials: 'include',
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
        query: {
            fetchPolicy: 'no-cache',
        },
    },
});

export { apolloClient };
