<script lang="ts" setup>
import { VForm, VBtn } from 'vuetify/components';
import VPasswordField from '@/components/VPasswordField.vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from '@vue/apollo-composable';
import { useErrorHandler } from '@/composables/errors';
import { PASSWORD_RESET, VERIFY_USER } from '@/graphql/mutations';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import AppAlert from './AppAlert.vue';
import { onMounted } from 'vue';
import { useTemplateRef } from 'vue';
import { RouteName } from '@/router';

const route = useRoute();

const isResetRoute = route.name === RouteName['account.reset-password'];

const { t } = useI18n({
    messages: {
        nl: {
            submit: 'Verstuur',
            new_password: isResetRoute ? 'Nieuw Wachtwoord' : 'Wachtwoord',
            repeat_password: 'Herhaal Wachtwoord',
            errors: {
                passwords_do_not_match: 'Wachtwoorden komen niet overeen',
                failed: isResetRoute
                    ? 'Wachtwoord herstellen mislukt'
                    : 'Wachtwoord aanmaken mislukt',
            },
        },
        en: {
            submit: 'Submit',
            new_password: isResetRoute ? 'New Password' : 'Password',
            repeat_password: 'Repeat Password',
            errors: {
                passwords_do_not_match: 'Passwords do not match',
                failed: isResetRoute
                    ? 'Password reset failed'
                    : 'Password creation failed',
            },
        },
    },
});

const { handleError } = useErrorHandler();

const emit = defineEmits<{
    (e: 'submitted', token: string): void;
    (e: 'error'): void;
}>();

const newPasswordField =
    useTemplateRef<typeof VPasswordField>('newPasswordField');

const newPassword = ref('');
const repeatPassword = ref('');
const token = ref('');

onMounted(() => {
    if (typeof route.params.token !== 'string') {
        handleError(new Error('Invalid token'), undefined, true);
        return;
    }
    token.value = route.params.token;
});

const errors = ref<string[]>([]);

const { mutate: resetPassword, onDone: onResetDone } =
    useMutation(PASSWORD_RESET);
const { mutate: verifyUser, onDone: onVerifyDone } = useMutation(VERIFY_USER);

onResetDone(({ errors }) => {
    if (errors) handleError(new Error(t('errors.failed')));
});

onVerifyDone(({ errors }) => {
    if (errors) handleError(new Error(t('errors.failed')));
});

function validateForm() {
    errors.value = [];
    if (newPassword.value !== repeatPassword.value) {
        emit('error');
        errors.value.push('passwords_do_not_match');
        if (newPasswordField.value) newPasswordField.value.focus();
        handleError(new Error(t('errors.passwords_do_not_match')));
    }
}

async function handleSubmit() {
    validateForm();

    try {
        if (isResetRoute) {
            const resetPasswordData = await resetPassword({
                password: newPassword.value,
                token: token.value,
            });
            console.log(resetPasswordData);
            emit('submitted', resetPasswordData?.data.passwordReset.token);
        } else {
            const verifyUserData = await verifyUser({
                password: newPassword.value,
                token: token.value,
            });
            emit('submitted', verifyUserData?.data.verifyUser.token);
        }
    } catch (err) {
        emit('error');
        handleError(new Error());
    }
}
</script>

<template>
    <v-form class="mb-3" @submit.prevent="handleSubmit">
        <v-password-field
            ref="newPasswordField"
            v-model="newPassword"
            name="password"
            class="mb-3"
            :label="t('new_password')"
            hide-details
            autofocus
        ></v-password-field>

        <v-password-field
            v-model="repeatPassword"
            class="mb-6"
            name="password_confirmation"
            :label="t('repeat_password')"
            hide-details
        />
        <template v-if="errors && errors.length > 0">
            <app-alert
                v-for="error in errors"
                :key="error"
                :text="t('errors.' + error)"
                variant="error"
                class="mb-6"
            ></app-alert>
        </template>
        <v-btn
            :disabled="!newPassword || !repeatPassword"
            block
            type="submit"
            >{{ t('submit') }}</v-btn
        >
    </v-form>
</template>
