import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useAuthStore as useAuthStore } from '@/stores/auth';
import { RouteName } from './index';

export const authGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    const authStore = useAuthStore();

    if (to.meta.requiresAuth && !authStore.isLoggedIn) {
        next({
            name: RouteName['account.login'],
        });
    } else {
        next();
    }
};
