import { defineStore } from 'pinia';
import { apolloClient } from '@/plugins/apollo';
import type { Portfolio } from '@/types';
import { graphql } from '@/gql';
import type { PortfolioList } from '@/gql/graphql';

type PortfolioSetting = Pick<Portfolio, 'id' | 'name'>;
type OrganisationSetting = {
    id: string;
    name: string;
};

const PORTFOLIO_QUERY = graphql(`
    query PortfolioSanityCheck($portfolioId: ID!) {
        portfolio(portfolioId: $portfolioId) {
            id
            name
        }
    }
`);

const PORTFOLIOS_IN_ORG_QUERY = graphql(`
    query PortfoliosFallback($filters: [FilterCriterion]) {
        portfolios(filters: $filters) {
            items {
                id
                name
            }
        }
    }
`);

const ORGANISATION_QUERY = graphql(`
    query OrganisationSanityCheck($accountId: ID!) {
        account(accountId: $accountId) {
            id
            company
        }
    }
`);
export interface UserSettings {
    activePortfolio?: PortfolioSetting;
    mainPortfolio?: PortfolioSetting;
    activeOrganisation?: OrganisationSetting;
}

export const useUserSettingsStore = defineStore('user-settings', {
    state: () =>
        ({
            mainPortfolio: undefined,
            activePortfolio: undefined,
            activeOrganisation: undefined,
        }) as UserSettings,
    actions: {
        setActivePortfolio(portfolio: PortfolioSetting) {
            this.activePortfolio = portfolio;
        },
        setMainPortfolio(portfolio: PortfolioSetting) {
            // Active portfolio should always be set, so if it's not explicitly set, set it to the main portfolio
            if (!this.activePortfolio) {
                this.setActivePortfolio(portfolio);
            }

            this.mainPortfolio = portfolio;
        },
        setActiveOrganisation(organisation: OrganisationSetting) {
            this.activeOrganisation = organisation;
        },
        unsetActivePortfolio() {
            this.activePortfolio = undefined;
        },
        unsetMainPortfolio() {
            this.mainPortfolio = undefined;
        },
        unsetActiveOrganisation() {
            this.activeOrganisation = undefined;
        },
        async setFallbackPortfolio() {
            try {
                const { data } = await apolloClient.query<{
                    portfolios: PortfolioList;
                }>({
                    query: PORTFOLIOS_IN_ORG_QUERY,
                    variables: {
                        filters: [
                            {
                                field: 'account_id',
                                value: this.activeOrganisation?.id,
                            },
                        ],
                    },
                });
                const portfolio = data?.portfolios?.items[0];

                this.setActivePortfolio({
                    id: portfolio.id,
                    name: portfolio.name,
                });
            } catch (err) {
                console.error(err);
            }
        },
        reset() {
            this.unsetActiveOrganisation();
            this.unsetActivePortfolio();
            this.unsetMainPortfolio();
        },
        async _getPortfolio(id: string) {
            const { data } = await apolloClient.query({
                query: PORTFOLIO_QUERY,
                variables: {
                    portfolioId: id,
                    accountId: this.activeOrganisation?.id,
                },
            });

            return { data };
        },
        async _validateOrganization() {
            if (!this.activeOrganisation) return;

            try {
                const { data } = await apolloClient.query({
                    query: ORGANISATION_QUERY,
                    variables: {
                        accountId: this.activeOrganisation?.id,
                    },
                });

                if (data?.account?.id === this.activeOrganisation.id) return;
                this.unsetActiveOrganisation();
            } catch (err) {
                this.unsetActiveOrganisation();
                this.reset();
            }
        },
        async _validatePortfolio(portfolio: 'active' | 'main') {
            const _portfolio =
                portfolio === 'active'
                    ? this.activePortfolio
                    : this.mainPortfolio;

            if (!_portfolio) return;

            try {
                const { data } = await this._getPortfolio(_portfolio.id);
                if (data?.portfolio?.id === _portfolio.id) return;
                portfolio === 'active'
                    ? this.unsetActivePortfolio()
                    : this.unsetMainPortfolio();
            } catch (err) {
                portfolio === 'active'
                    ? this.unsetActivePortfolio()
                    : this.unsetMainPortfolio();
            }
        },
        async _validatePortfolios() {
            const validations = [];
            if (this.activePortfolio)
                validations.push(this._validatePortfolio('active'));
            if (this.mainPortfolio)
                validations.push(this._validatePortfolio('main'));

            await Promise.all(validations);
        },
        async validateActiveOrganisation() {},
        async validate() {
            await this._validatePortfolios();
            await this._validateOrganization();
        },
    },
    persist: true,
});
