import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useUserSettingsStore } from '@/stores/user-settings';
import { usePolicyStore } from '@/stores/policy';
import { provideApolloClient, useApolloClient } from '@vue/apollo-composable';
import { CURRENT_USER } from '@/graphql/queries';
import { apolloClient } from '@/plugins/apollo';
import { computed, reactive } from 'vue';
import * as Sentry from '@sentry/vue';

export interface LoginData {
    email: string;
    password: string;
}

export const useAuthStore = defineStore('auth', () => {
    const userSettingsStore = useUserSettingsStore();
    const userStore = useUserStore();
    const policyStore = usePolicyStore();
    provideApolloClient(apolloClient);
    const { resolveClient } = useApolloClient();
    const client = resolveClient();

    const token = useLocalStorage<string | null>('token', null);
    const credentials = reactive<{
        email: string | undefined;
        password: string | undefined;
    }>({
        email: undefined,
        password: undefined,
    });
    const isLoggedIn = computed(() => token.value !== null);

    function setCredentials(data: LoginData) {
        credentials.email = data.email;
        credentials.password = data.password;
    }

    function clearCredentials() {
        credentials.email = undefined;
        credentials.password = undefined;
    }

    async function logInWithToken(newToken: string) {
        clearCredentials();
        token.value = newToken;

        try {
            const { data, error, errors } = await client.query({
                query: CURRENT_USER,
            });

            if (error || errors) {
                console.log({ error, errors });
                // TODO: Handle error
                return;
            }

            await userSettingsStore.validate();

            // TODO: Handle error
            if (!data?.currentUser) return;

            userStore.setUser({
                id: data.currentUser.id,
                firstName: data.currentUser.firstName,
                lastName: data.currentUser.lastName,
                email: data.currentUser.email,
                profileColor: data.currentUser.profileColor,
                organisationId: data.currentUser.account.id,
                accessRoles: data.currentUser.access?.roles ?? [],
            });

            policyStore.setPolicyStatements(data.currentUser.access);

            userSettingsStore.setActiveOrganisation({
                id: data.currentUser.account.id,
                name: data.currentUser.account.company,
            });

            Sentry.setUser({
                id: data.currentUser.id,
            });

            document.cookie = 'returning_user=1;';
        } catch (err) {
            console.log(err);
        }
    }

    function logout(reload = true) {
        token.value = null;
        userStore.reset();

        // Reset the active portfolio to the main portfolio for next login
        userSettingsStore.activePortfolio = userSettingsStore.mainPortfolio;

        // Remove policies
        policyStore.$reset();

        reload && window.location.reload();
        // Clear the logged in user from Sentry scope
        Sentry.setUser(null);
    }

    return {
        token,
        credentials,
        setCredentials,
        clearCredentials,
        logInWithToken,
        isLoggedIn,
        logout,
    };
});
