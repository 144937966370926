import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { Observable } from '@apollo/client/utilities';
import { TokenService } from './token-service';
import { getRequestToken, retry } from './utils';
import { getCookie } from '@/utils';
import { useAuthStore } from '@/stores/auth';
import { ErrorType } from '@/gql/graphql';

export const tokenExpiredErrorHandler = onError(
    ({ graphQLErrors, forward, operation }) => {
        const tokenExpiredError = graphQLErrors?.find((err) => {
            return err.extensions?.type === ErrorType.TOKEN_EXPIRED;
        });

        if (!tokenExpiredError) {
            return;
        }

        return new Observable((observer) => {
            TokenService.getNewToken()
                .then(() => {
                    const subscriber = {
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                    };
                    forward(operation).subscribe(subscriber);
                })
                .catch((error) => {
                    observer.error(error);
                });
        });
    }
);

export const unauthorizedErrorHandler = onError(
    ({ graphQLErrors, forward, operation }) => {
        const hasUnauthorizedError = graphQLErrors?.find((err) => {
            return err.extensions?.type === ErrorType.UNAUTHORIZED;
        });

        if (!hasUnauthorizedError) {
            return;
        }

        const currentToken = localStorage.getItem('token');
        const requestToken = getRequestToken(operation.getContext());
        if (requestToken && currentToken && currentToken !== requestToken) {
            return retry(forward, operation, currentToken);
        }

        const authStore = useAuthStore();
        authStore.logout();
    }
);

export const randomStringMiddleware = setContext((_, { headers }) => {
    let name = '__Secure-random_string';
    if (import.meta.env.VITE_ENV === 'development') {
        name = 'random_string';
    }

    return {
        headers: {
            ...headers,
            'random-string': getCookie(name),
        },
    };
});
