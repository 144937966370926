import type { DefaultContext, NextLink, Operation } from '@apollo/client/core';

export function retry(forward: NextLink, operation: Operation, token: string) {
    operation.setContext((oldContext: DefaultContext) => {
        const newHeaders = {
            ...oldContext.headers,
            authorization: `Bearer ${token}`,
        };
        return { ...oldContext, headers: newHeaders };
    });
    return forward(operation);
}

export function getRequestToken(context: DefaultContext) {
    try {
        return context.headers.authorization.split(' ')[1];
    } catch {
        return undefined;
    }
}

export function getAuth(tokenName: string) {
    const token = localStorage.getItem(tokenName);
    return token ? `Bearer ${token}` : '';
}
