<script lang="ts" setup>
import { ref } from 'vue';
import { VForm, VBtn, VOtpInput } from 'vuetify/components';
import { CREATE_USER_TOKEN } from '@/graphql/mutations';
import { useRecaptchaProvider, useChallengeV3 } from 'vue-recaptcha';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { useI18n } from 'vue-i18n';
import { useMutation } from '@vue/apollo-composable';
import { useErrorHandler } from '@/composables/errors';
import { RouteName } from '@/router';

const { t } = useI18n({
    inheritLocale: true,
    messages: {
        nl: {
            submit: 'Versturen',
            return_to_login: 'Terug naar inloggen',
            missing_email: 'E-mail ontbreekt',
            missing_password: 'Wachtwoord ontbreekt',
        },
        en: {
            submit: 'Submit',
            return_to_login: 'Return to login',
            missing_email: 'E-mail ontbreekt',
            missing_password: 'Missing password',
        },
    },
});

const otp = ref('');
const recaptchaToken = ref('');
const loading = ref(false);

const { mutate: createUserToken, onDone: onCreateUserTokenDone } =
    useMutation(CREATE_USER_TOKEN);

const router = useRouter();
const authStore = useAuthStore();
const { handleError } = useErrorHandler();

useRecaptchaProvider();
const { execute } = useChallengeV3('login');

async function handleSubmit() {
    loading.value = true;
    const recaptchaTokenResponse = await execute();
    recaptchaToken.value = recaptchaTokenResponse;

    if (!authStore.credentials.email) {
        handleError(new Error(t('missing_email')));
        return;
    }
    if (!authStore.credentials.password) {
        handleError(new Error(t('missing_password')));
        return;
    }

    try {
        await createUserToken({
            input: {
                email: authStore.credentials.email,
                password: authStore.credentials.password,
                recaptchaToken: recaptchaToken.value,
                totp: otp.value,
            },
        });
    } catch (err) {
        // TODO: Handle err
        handleError(err);
    } finally {
        loading.value = false;
    }
}

onCreateUserTokenDone(async ({ data, errors }) => {
    // TODO: Handle errors
    if (errors) {
        console.log(errors);
    }

    if (!data?.createUserToken?.token) return;
    try {
        await authStore.logInWithToken(data.createUserToken.token);
        router.push({ name: RouteName['dashboard.home'] });
    } catch (err) {
        handleError(err);
    }
});
</script>

<template>
    <v-form class="mfa-form" @submit.prevent>
        <v-otp-input
            v-model="otp"
            class="mb-4"
            autofocus
            :loading
            @finish="handleSubmit"
        ></v-otp-input>
        <v-btn :disabled="loading" type="submit" @click="handleSubmit">{{
            t('submit')
        }}</v-btn>
        <v-btn :to="{ name: RouteName['account.login'] }" variant="outlined">{{
            t('return_to_login')
        }}</v-btn>
    </v-form>
</template>

<style lang="scss" scoped>
.mfa-form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
</style>
