import { graphql } from '@/gql';

export default graphql(`
    query PortfoliosAndRoles($accountId: String) {
        portfolios(
            filters: [
                { field: "account_id", value: $accountId, operator: EQUAL }
            ]
            sorting: [{ field: "name", direction: ASC }]
        ) {
            items {
                id
                name
            }
        }
        roles(
            filters: [
                { field: "account_id", value: $accountId, operator: EQUAL }
            ]
        ) {
            items {
                id
                name
                accountId
                portfolioId
            }
        }
    }
`);
