import { graphql } from '../../gql';

export default graphql(`
    query CurrentUser {
        currentUser {
            account {
                id
                company
            }
            id
            firstName
            lastName
            email
            profileColor
            access {
                roles {
                    name
                    id
                    portfolioId
                    accountId
                    policies {
                        statement {
                            resource
                            action
                            effect
                        }
                    }
                }
                accessPolicies {
                    id
                    statement {
                        resource
                        action
                        effect
                    }
                }
            }
        }
    }
`);
