import {
    createRouter,
    createWebHistory,
    type RouteRecordRaw,
} from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { authGuard } from './guards';
import { useMixpanel } from '@/composables/mixpanel';
import { h } from 'vue';
import { RouterView } from 'vue-router';

/*
 When lazy loading routes, group them into chunks as per https://router.vuejs.org/guide/advanced/lazy-loading.html#with-vite 
 */

// Define route names here to avoid typos and mis-matches
export enum RouteName {
    'account' = 'account',
    'account.login' = 'account.login',
    'account.password' = 'account.password',
    'account.forgot-password' = 'account.forgot-password',
    'account.mfa' = 'account.mfa',
    'account.reset-password' = 'account.reset-password',
    'account.verify' = 'account.verify',
    'dashboard.about' = 'dashboard.about',
    'dashboard.home' = 'dashboard.home',
    'dashboard.profile' = 'dashboard.profile',
    'dashboard.portfolios' = 'dashboard.portfolios',
    'dashboard.framework' = 'dashboard.framework',
    'dashboard.metro' = 'dashboard.metro',
    'dashboard.organisations' = 'dashboard.organisations',
    'dashboard.create-organisation' = 'dashboard.create-organisation',
    'dashboard.update-organisation' = 'dashboard.update-organisation',
    'dashboard.programs' = 'dashboard.programs',
    'dashboard.program' = 'dashboard.program',
    'dashboard.projects' = 'dashboard.projects',
    'dashboard.project' = 'dashboard.project',
    'dashboard.project.deliverables' = 'dashboard.project.deliverables',
    'dashboard.project.subprojects' = 'dashboard.project.subprojects',
    'dashboard.project.highlights' = 'dashboard.project.highlights',
    'dashboard.project.downloads' = 'dashboard.project.downloads',
    'dashboard.deliverable' = 'dashboard.deliverable',
    'dashboard.tags' = 'dashboard.tags',
    'dashboard.users' = 'dashboard.users',
    'dashboard.ideas' = 'dashboard.ideas',
    'dashboard.idea' = 'dashboard.idea',
    'dashboard.themes' = 'dashboard.themes',
    'dashboard.documents' = 'dashboard.documents',
    'dashboard.departments' = 'dashboard.departments',
}

export const routes: RouteRecordRaw[] = [
    {
        path: '/',
        component: HomeView,
        beforeEnter: (to, from, next) => {
            next({ name: RouteName['dashboard.home'] });
        },
    },
    {
        path: '/account',
        name: RouteName['account'],
        component: () => import('../layouts/AccountLayout.vue'),
        children: [
            {
                path: 'login',
                name: RouteName['account.login'],
                component: () => import('../views/LoginView.vue'),
            },
            {
                path: 'password',
                name: RouteName['account.password'],
                component: () => import('../views/LoginPasswordView.vue'),
            },
            {
                path: 'mfa',
                name: RouteName['account.mfa'],
                component: () => import('../views/MFAView.vue'),
            },
            {
                path: 'reset-password',
                name: RouteName['account.forgot-password'],
                component: () => import('../views/ForgotPasswordView.vue'),
            },
            {
                path: 'reset-password/:token',
                name: RouteName['account.reset-password'],
                component: () => import('../views/ResetPasswordView.vue'),
            },
            {
                path: 'verify/:token',
                name: RouteName['account.verify'],
                component: () => import('../views/ResetPasswordView.vue'),
            },
            {
                path: 'saml',
                name: 'SAML',
                component: () => import('@/views/SAMLView.vue'),
            },
        ],
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../layouts/AppLayout.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: RouteName['dashboard.home'],
                component: () => import('../views/DashboardView.vue'),
                meta: {
                    breadcrumbs: {
                        dark: false,
                        hideQuickMenu: false,
                        items: [],
                    },
                },
            },
            {
                path: 'portfolios',
                name: RouteName['dashboard.portfolios'],
                component: () =>
                    import('../views/DashboardPortfolioCascadingView.vue'),
                meta: {
                    breadcrumbs: {
                        items: [],
                    },
                },
            },
            {
                path: 'portfolios/:id/metro',
                name: RouteName['dashboard.metro'],
                component: () => import('@/views/DashboardMetroView.vue'),
                meta: {
                    breadcrumbs: {
                        items: [],
                    },
                },
            },
            {
                path: 'portfolios/:id/framework',
                name: RouteName['dashboard.framework'],
                component: () => import('../views/DashboardFrameworkView.vue'),
                meta: {
                    breadcrumbs: {
                        items: [],
                    },
                },
            },
            {
                path: 'profile',
                name: RouteName['dashboard.profile'],
                component: () => import('../views/DashboardProfileView.vue'),
                meta: {
                    breadcrumbs: {
                        hideQuickMenu: true,
                        items: [],
                    },
                },
            },
            {
                path: 'tags',
                name: RouteName['dashboard.tags'],
                component: () => import('@/views/DashboardTagListView.vue'),
                meta: {
                    breadcrumbs: {
                        items: [],
                    },
                },
            },
            {
                path: 'documents',
                name: RouteName['dashboard.documents'],
                component: () =>
                    import('@/views/DashboardDocumentListView.vue'),
                meta: {
                    breadcrumbs: {
                        items: [],
                    },
                },
            },
            {
                path: 'departments',
                name: RouteName['dashboard.departments'],
                component: () =>
                    import('@/views/DashboardDepartmentListView.vue'),
                meta: {
                    breadcrumbs: {
                        items: [],
                    },
                },
            },
            {
                path: 'users',
                name: RouteName['dashboard.users'],
                component: () => import('@/views/DashboardUserListView.vue'),
                meta: {
                    breadcrumbs: {
                        items: [],
                    },
                },
            },
            {
                path: 'organisations',
                component: { render: () => h(RouterView) },
                children: [
                    {
                        path: '',
                        name: RouteName['dashboard.organisations'],
                        component: () =>
                            import('@/views/DashboardOrganisationListView.vue'),
                        meta: {
                            breadcrumbs: {
                                items: [],
                            },
                        },
                    },
                    {
                        path: 'new',
                        name: RouteName['dashboard.create-organisation'],
                        component: () =>
                            import(
                                '@/views/DashboardOrganisationCreateView.vue'
                            ),
                        meta: {
                            breadcrumbs: {
                                items: [],
                            },
                        },
                    },
                    {
                        path: ':id/edit',
                        name: RouteName['dashboard.update-organisation'],
                        component: () =>
                            import('@/views/DashboardOrganisationEditView.vue'),
                        meta: {
                            breadcrumbs: {
                                items: [],
                            },
                        },
                    },
                ],
            },
            {
                path: 'projects',
                component: { render: () => h(RouterView) },
                children: [
                    {
                        path: '',
                        name: RouteName['dashboard.projects'],
                        component: () =>
                            import('@/views/DashboardProjectListView.vue'),
                    },
                    {
                        path: ':id',
                        component: () =>
                            import('@/views/DashboardProjectView.vue'),
                        children: [
                            {
                                path: '',
                                name: RouteName['dashboard.project'],
                                component: () =>
                                    import(
                                        '@/components/ProjectViewTabDescription.vue'
                                    ),
                            },
                            {
                                name: RouteName[
                                    'dashboard.project.deliverables'
                                ],
                                path: 'deliverables',
                                component: () =>
                                    import(
                                        '@/components/ProjectViewTabDeliverables.vue'
                                    ),
                            },
                            {
                                name: RouteName[
                                    'dashboard.project.subprojects'
                                ],
                                path: 'subprojects',
                                component: () =>
                                    import(
                                        '@/components/ProjectViewTabSubprojects.vue'
                                    ),
                            },
                            {
                                name: RouteName['dashboard.project.highlights'],
                                path: 'highlights',
                                component: () =>
                                    import(
                                        '@/components/ProjectViewTabHighlights.vue'
                                    ),
                            },
                            {
                                name: RouteName['dashboard.project.downloads'],
                                path: 'downloads',
                                component: () =>
                                    import(
                                        '@/components/ProjectViewTabDocuments.vue'
                                    ),
                            },
                        ],
                        meta: {
                            breadcrumbs: {
                                dark: true,
                                items: [],
                            },
                        },
                    },
                ],
                meta: {
                    breadcrumbs: {
                        items: [],
                    },
                },
            },
            {
                path: 'ideas',
                component: { render: () => h(RouterView) },
                children: [
                    {
                        path: '',
                        name: RouteName['dashboard.ideas'],
                        component: () =>
                            import('@/views/DashboardIdeaListView.vue'),
                    },
                    {
                        path: ':id',
                        name: RouteName['dashboard.idea'],
                        component: () =>
                            import('@/views/DashboardIdeaView.vue'),
                        meta: {
                            breadcrumbs: {
                                items: [],
                            },
                        },
                    },
                ],
                meta: {
                    breadcrumbs: {
                        items: [],
                    },
                },
            },
            {
                path: 'programs',
                component: { render: () => h(RouterView) },
                children: [
                    {
                        path: '',
                        name: RouteName['dashboard.programs'],
                        component: () =>
                            import('@/views/DashboardProgramListView.vue'),
                        children: [],
                        meta: {
                            breadcrumbs: {
                                items: [],
                            },
                        },
                    },
                    {
                        path: ':id',
                        name: RouteName['dashboard.program'],
                        component: () =>
                            import('@/views/DashboardProgramView.vue'),
                        meta: {
                            breadcrumbs: {
                                items: [],
                            },
                        },
                    },
                ],
            },
            {
                path: 'themes',
                component: { render: () => h(RouterView) },
                children: [
                    {
                        path: '',
                        name: RouteName['dashboard.themes'],
                        component: () =>
                            import('@/views/DashboardThemeListView.vue'),
                        children: [],
                        meta: {
                            breadcrumbs: {
                                items: [],
                            },
                        },
                    },
                ],
            },
            {
                path: 'deliverables',
                component: { render: () => h(RouterView) },
                children: [
                    {
                        path: ':id',
                        name: RouteName['dashboard.deliverable'],
                        component: () =>
                            import('@/views/DashboardDeliverableView.vue'),
                    },
                ],
                meta: {
                    breadcrumbs: {
                        items: [],
                    },
                },
            },
            {
                path: 'about',
                name: RouteName['dashboard.about'],
                component: () => import('@/views/DashboardAbout.vue'),
                meta: {
                    breadcrumbs: {
                        items: [],
                    },
                },
            },
        ],
    },
    // Redirects
    {
        path: '/login',
        redirect: '/account/login',
    },
];

// Styleguide routes, only built in non production environments
if (import.meta.env.MODE !== 'production') {
    routes.push({
        path: '/docs',
        name: 'docs',
        components: {
            default: () => import('../layouts/DocsLayout.vue'),
        },
        children: [
            {
                path: 'typography',
                component: () => import('../views/DocsTypographyView.vue'),
            },
            {
                path: 'buttons',
                component: () => import('../views/DocsButtonsView.vue'),
            },
            {
                path: 'icons',
                component: () => import('../views/DocsIconsView.vue'),
            },
            {
                path: 'colors',
                component: () => import('../views/DocsColorsView.vue'),
            },
            {
                path: 'components',
                component: () => import('../views/DocsComponentsView.vue'),
            },
            {
                path: 'cascade',
                component: () => import('../views/DocsCascadeView.vue'),
            },
        ],
    });
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.afterEach(({ name, path }) => {
    const mixpanel = useMixpanel();
    mixpanel?.track_pageview({ page: name, path });
});
router.beforeEach(authGuard);
export default router;
