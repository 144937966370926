import type { ProjectStatus } from '@/gql/graphql';
import type { User } from '@sentry/vue';

export type UUID = string;

export type ProjectDisplayStatus =
    | 'pending' // project.DeliverableState === 'normal' && !project.finished && !project.onHold
    | 'bottleneck' // project.DeliverableState === 'bottleneck'
    | 'escalation' // project.DeliverableState === 'escalation'
    | 'on_hold' // project.onHold === true
    | 'finished'; // project.finished === true

export type AccessRole = {
    id: string;
    name: string;
    accountId: string;
    portfolioId: string | null;
};

export type DateTime = string;
export type JsonString = string;
export type Account = {
    accountId?: string;
    color: string;
    company: string;
    createdAt?: DateTime;
    deletedAt?: DateTime;
    id: string;
    status: number;
    themeSettings: JsonString;
    tracking: Boolean;
    updatedAt?: DateTime;
};

export type Deliverable = {};

export type Department = {
    account?: Account;
    accountId?: string;
    color?: string;
    createdAt?: DateTime;
    deleteReason?: string;
    id: string;
    name: string;
    portfolio?: Portfolio;
    portfolioId: number;
    projects?: [Project];
    updatedAt?: DateTime;
    uuid?: string;
};

export type Portfolio = {
    abbreviation: string;
    account: Account;
    accountId: string;
    company: string;
    createdAt: string;
    deletedAt: string;
    departments: Department[];
    description: string;
    grid: string;
    id: string;
    name: string;
    parentId: string | null;
    portfolioProjects: PortfolioProject[];
    programs: Program[];
    projects: Project[];
    roles: Role[];
    rows: number;
    tags: Tag[];
    updatedAt: string;
};

export type PortfolioProject = {};

export type Program = {};

export type Document = {};

export type Highlight = {};

export type Phase = {};

export enum ProjectStatus2 {
    'ACTIVE' = 'active',
    'ON_HOLD' = 'on_hold',
    'FINISHED' = 'finished',
}

export type Project = {
    createdAt?: DateTime;
    deleteReason?: string;
    deletedAt?: DateTime;
    deliverables?: Deliverable[];
    departments?: Department[];
    description: string;
    documents?: Document[];
    endDate?: DateTime;
    finished: Boolean;
    goal?: string;
    highlights?: Highlight[];
    id: string;
    masterPortfolio?: Portfolio;
    masterPortfolioId?: number;
    name: string;
    onHold?: DateTime;
    onTrack: Boolean;
    parentProjectId?: number;
    phases?: Phase[];
    portfolioId?: string;
    portfolioProjects?: PortfolioProject[];
    portfolios?: Portfolio[];
    previews?: Preview[];
    programManager?: User;
    programs?: Program[];
    projectManager?: User;
    projectTeam?: string;
    risks?: string;
    scope?: string;
    stakeholders?: string;
    status: ProjectStatus;
    subProjects?: Project[];
    tagValues?: TagValue[];
    themes?: Theme[];
    updatedAt?: DateTime;
    users?: ProjectUser[];
    uuid?: string;
};

export type TagValue = {
    account?: Account;
    accountId: number;
    id: string;
    name: string;
    portfolioId: number;
    projects: Project[];
    tag: Tag;
    tagId: string;
    uuid: string;
};
export type ProjectUser = {
    account?: Account;
    accountId?: string;
    id: string;
    portfolio?: string;
    project?: Project;
    role: ProjectRole;
    user?: User;
    uuid?: string;
};

export type ProjectRole = {};

export type Theme = {};

export type Preview = {};

export type Role =
    | 'jigseye'
    | 'admin'
    | 'board'
    | 'program_manager'
    | 'project_manager'
    | 'user';

export type Tag = {};

export enum PolicyAction {
    ALL = '*',
    ANONYMOUS = 'none:__',
    SERVICE = 'service:__',
    INTROSPECTION_READ = 'introspection:read',
    SWITCH_USER = 'switch:__',
    ADJUST_DEADLINE = 'deadline:__',
    UPDATE_PROJECT_STATUS = 'project_status:__',
    HOLD_PROJECT = 'project_hold:__',
    FINISH_PROJECT = 'project_finish:__',
    CONVERT_SUBPROJECT = 'project_convert:__',

    MANUAL_WRITE = 'manual:write',
    MANUAL_LIST = 'manual:list',

    NEWS_WRITE = 'news:write',
    NEWS_LIST = 'news:list',

    USER_CREATE = 'user:create',
    USER_READ = 'user:read',
    USER_WRITE = 'user:write',
    USER_LIST = 'user:list',
    USER_ALL = 'user:*',

    ACCOUNT_CREATE = 'account:create',
    ACCOUNT_DELETE = 'account:create',
    ACCOUNT_READ = 'account:read',
    ACCOUNT_WRITE = 'account:write',
    ACCOUNT_LIST = 'account:list',
    ACCOUNT_ALL = 'account:*',

    PORTFOLIO_CREATE = 'portfolio:create',
    PORTFOLIO_DELETE = 'portfolio:delete',
    PORTFOLIO_READ = 'portfolio:read',
    PORTFOLIO_WRITE = 'portfolio:write',
    PORTFOLIO_LIST = 'portfolio:list',
    PORTFOLIO_ALL = 'portfolio:*',

    CELL_READ = 'cell:read',
    CELL_WRITE = 'cell:write',
    CELL_LIST = 'cell:list',
    CELL_ALL = 'cell:*',

    PROGRAM_CREATE = 'program:create',
    PROGRAM_READ = 'program:read',
    PROGRAM_WRITE = 'program:write',
    PROGRAM_LIST = 'program:list',
    PROGRAM_ALL = 'program:*',

    DEPARTMENT_CREATE = 'department:create',
    DEPARTMENT_READ = 'department:read',
    DEPARTMENT_WRITE = 'department:write',
    DEPARTMENT_LIST = 'department:list',
    DEPARTMENT_ALL = 'department:*',

    THEME_CREATE = 'theme:create',
    THEME_READ = 'theme:read',
    THEME_WRITE = 'theme:write',
    THEME_LIST = 'theme:list',
    THEME_ALL = 'theme:*',

    PROJECT_CREATE = 'project:create',
    PROJECT_READ = 'project:read',
    PROJECT_WRITE = 'project:write',
    PROJECT_DELETE = 'project:delete',
    PROJECT_LIST = 'project:list',
    PROJECT_ALL = 'project:*',

    PROJECT_USER_ALL = 'project_user:*',
    PROJECT_USER_LIST = 'project_user:list',

    DELIVERABLE_CREATE = 'deliverable:create',
    DELIVERABLE_READ = 'deliverable:read',
    DELIVERABLE_WRITE = 'deliverable:write',
    DELIVERABLE_LIST = 'deliverable:list',
    DELIVERABLE_ALL = 'deliverable:*',

    BOTTLENECK_CREATE = 'bottleneck:create',
    BOTTLENECK_READ = 'bottleneck:read',
    BOTTLENECK_WRITE = 'bottleneck:write',
    BOTTLENECK_LIST = 'bottleneck:list',
    BOTTLENECK_ALL = 'bottleneck:*',

    ESCALATION_CREATE = 'escalation:create',
    ESCALATION_READ = 'escalation:read',
    ESCALATION_WRITE = 'escalation:write',
    ESCALATION_LIST = 'escalation:list',
    ESCALATION_ALL = 'escalation:*',

    DOCUMENT_CREATE = 'document:create',
    DOCUMENT_READ = 'document:read',
    DOCUMENT_WRITE = 'document:write',
    DOCUMENT_LIST = 'document:list',
    DOCUMENT_ALL = 'document:*',

    TAG_CREATE = 'tag:create',
    TAG_READ = 'tag:read',
    TAG_WRITE = 'tag:write',
    TAG_LIST = 'tag:list',
    TAG_ALL = 'tag:*',

    TAG_VALUE_LIST = 'tag_value:list',
    TAG_VALUE_ALL = 'tag_value:*',

    TASK_CREATE = 'task:create',
    TASK_READ = 'task:read',
    TASK_WRITE = 'task:write',
    TASK_LIST = 'task:list',
    TASK_ALL = 'task:*',

    PHASE_LIST = 'phase:list',
    PHASE_ALL = 'phase:*',

    HIGHLIGHT_LIST = 'highlight:list',
    HIGHLIGHT_ALL = 'highlight:*',

    PREVIEW_LIST = 'preview:list',
    PREVIEW_ALL = 'preview:*',

    ACCESS_READ = 'access:read',
    POLICY_READ = 'policy:read',
    POLICY_WRITE = 'policy:write',
    POLICY_READ_WRITE = 'policy:*',

    ROLE_WRITE = 'role:write',
    ROLE_READ = 'role:read',
    ROLE_READ_WRITE = 'role:*',
}

export enum UserRoles {
    JigsEye = 'JigsEye',
    Admin = 'Admin',
    ProgramManager = 'Program manager',
    ProjectManager = 'Project manager',
    User = 'User',
}

type ColorName =
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'error'
    | 'neutral'
    | 'neutral-variant';
type ColorValue =
    | '0'
    | '10'
    | '20'
    | '30'
    | '40'
    | '50'
    | '60'
    | '70'
    | '80'
    | '90'
    | '95'
    | '99'
    | '100';

export type Color = `${ColorName}-${ColorValue}`;

export interface TeamMember {
    id: string;
    firstName: string;
    lastName: string;
}
