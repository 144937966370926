<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import PasswordResetRequestForm from '@/components/PasswordResetRequestForm.vue';
import { ref } from 'vue';
import { VBtn } from 'vuetify/components';

const { t } = useI18n({
    inheritLocale: true,
    messages: {
        nl: {
            page_title: 'Wachtwoord vergeten',
            page_title_sent: 'E-mail verstuurd',
            page_subtitle:
                'Vul je e-mailadres in zodat we je een e-mail kunnen sturen waarmee je een nieuwe wachtwoord kunt aanmaken.',
            page_subtitle_sent:
                'Je aanvraag is gelukt. Je ontvangt een e-mail met een link waarmee je een nieuw wachtwoord kunt aanmaken',
            return_to_login: 'Terug naar inloggen',
        },
        en: {
            page_title: 'Forgot password',
            page_title_sent: 'E-mail sent',
            page_subtitle:
                'Enter your e-mail address so we can send you an e-mail with which you can create a new password.',
            page_subtitle_sent:
                'Your request was successful. You will receive an e-mail with a link to create a new password.',
            return_to_login: 'Return to login',
        },
    },
});

const isSent = ref(false);

function handleSubmission() {
    isSent.value = true;
}
</script>

<template>
    <h1 class="text-h1 mb-5">
        {{ !isSent ? t('page_title') : t('page_title_sent') }}
    </h1>
    <p class="text-label-medium text-outline mb-9">
        {{ !isSent ? t('page_subtitle') : t('page_subtitle_sent') }}
    </p>
    <PasswordResetRequestForm
        v-show="!isSent"
        class="mb-8"
        @submitted="handleSubmission"
    />
    <v-btn to="/account/login" variant="outlined">{{
        t('return_to_login')
    }}</v-btn>
</template>
