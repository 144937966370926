<script lang="ts" setup>
import { REQUEST_PASSWORD_RESET } from '@/graphql/mutations';
import { useMutation } from '@vue/apollo-composable';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VTextField, VBtn, VForm } from 'vuetify/components';
import { useChallengeV3, useRecaptchaProvider } from 'vue-recaptcha';
import { useRoute } from 'vue-router';

useRecaptchaProvider();
const route = useRoute();

const { t } = useI18n({
    inheritLocale: true,
    messages: {
        nl: {
            label: 'E-mailadres',
            submit_button: 'Versturen',
        },
        en: {
            label: 'E-mail address',
            submit_button: 'Send',
        },
    },
});

const emit = defineEmits<{ (e: 'submitted'): void; (e: 'error'): void }>();

const recaptchaToken = ref('');
const email = ref('');

onBeforeMount(() => {
    if (route.query.email) {
        email.value = route.query.email as string;
    }
});

const { execute } = useChallengeV3('reset_password');
const { mutate, onDone, onError } = useMutation(REQUEST_PASSWORD_RESET);

onDone(async ({ data, errors }) => {
    if (errors) {
        emit('error');
        errors.forEach((err) => {
            console.log({ name: err.name, message: err.message });
        });
    }

    if (data) {
        console.log(data);
    }

    emit('submitted');
});

onError(({ name }) => {
    // TODO: Handle errors
    emit('error');
    console.log(name);
});

async function handleSubmit() {
    emit('submitted');
    const recaptchaTokenResponse = await execute();
    recaptchaToken.value = recaptchaTokenResponse;

    try {
        await mutate({
            email: email.value,
            recaptchaToken: recaptchaToken.value,
        });
    } catch (err) {
        throw new Error('Unable to request password reset');
    }
}
</script>

<template>
    <v-form class="reset-form" @submit.prevent="handleSubmit">
        <v-text-field
            v-model="email"
            :hide-details="true"
            :placeholder="t('label')"
            :label="t('label')"
            type="email"
            name="email"
            class="mb-5"
            autofocus
        ></v-text-field>
        <v-btn type="submit" class="mb-2">{{ t('submit_button') }}</v-btn>
    </v-form>
</template>

<style lang="scss" scoped>
.reset-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;
}
</style>
