import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import { initials } from '@/utils';
import type { AccessRole } from '@/types';

export interface UserData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    profileColor: string;
    organisationId: string;
    accessRoles: AccessRole[];
    impersonating: boolean;
}

export const useUserStore = defineStore('user', {
    state: () => ({
        id: useStorage<string | null>('id', null),
        firstName: useStorage<string | null>('firstName', null),
        lastName: useStorage<string | null>('lastName', null),
        email: useStorage<string | null>('email', null),
        profileColor: useStorage<string | undefined>('profileColor', undefined),
        organisationId: useStorage<string | undefined>(
            'organisationId',
            undefined
        ),
        accessRoles: useStorage<AccessRole[]>('roles', []),
        impersonating: useStorage('impersonating', false),
    }),
    actions: {
        setUser(data: Omit<UserData, 'impersonating'>) {
            this.id = data.id;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.email = data.email;
            this.profileColor = data.profileColor;
            this.organisationId = data.organisationId;
            this.accessRoles = data.accessRoles;
        },
        updateProfileColor(color: string) {
            this.profileColor = color;
        },
        setImpersonating(impersonating: boolean) {
            this.impersonating = impersonating;
        },
        reset() {
            this.id = null;
            this.firstName = null;
            this.lastName = null;
            this.email = null;
            this.profileColor = undefined;
            this.organisationId = undefined;
            this.accessRoles = [];
            this.impersonating = false;
        },
    },
    getters: {
        fullName: ({ firstName, lastName }) => {
            return firstName
                ? lastName
                    ? `${firstName} ${lastName}`
                    : firstName
                : '';
        },
        initials: ({ firstName, lastName }) => {
            return initials(`${firstName} ${lastName}`);
        },
    },
});
