<script lang="ts" setup>
import LoginEmailForm from '@/components/LoginEmailForm.vue';
import { onBeforeMount } from 'vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { getCookie } from '@/utils';

const isFirstVisit = ref(true);

onBeforeMount(() => {
    isFirstVisit.value = !getCookie('returning_user');
});

const { t } = useI18n({
    inheritLocale: true,
    messages: {
        nl: {
            page_title: 'Welkom bij JigsEye Insight',
            page_title_returning: 'Welkom terug',
            page_subtitle: 'Log in met je JigsEye Insight account',
        },
        en: {
            page_title: 'Welcome to JigsEye Insight',
            page_title_returning: 'Welcome back',
            page_subtitle: 'Log in using your JigsEye Insight account',
        },
    },
});
</script>

<template>
    <h1 class="text-h1 mb-5">
        {{ isFirstVisit ? t('page_title') : t('page_title_returning') }}
    </h1>
    <p class="text-label-medium text-outline mb-9">
        {{ t('page_subtitle') }}
    </p>
    <LoginEmailForm class="mb-8" />
</template>

<style lang="scss" scoped>
.text-h1 {
    @supports (text-wrap: balance) {
        text-wrap: balance;
    }
}
</style>
