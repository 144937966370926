<script lang="ts" setup>
import MfaAuthenticationForm from '@/components/MfaAuthenticationForm.vue';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouteName } from '@/router';

const authStore = useAuthStore();
const router = useRouter();

const { t } = useI18n({
    inheritLocale: true,
    messages: {
        nl: {
            page_title: 'Verificatie in twee stappen',
            page_subtitle:
                'Vul de code van de authenticatie app in voor de twee-staps-verificatie',
        },
        en: {
            page_title: 'Two-step verification',
            page_subtitle: 'Enter the code from the authentication app',
        },
    },
});

onMounted(() => {
    if (!authStore.credentials.email || !authStore.credentials.password) {
        router.push({ name: RouteName['account.login'] });
    }
});
</script>

<template>
    <h1 class="text-h1 mb-5">{{ t('page_title') }}</h1>
    <p class="text-label-medium text-outline mb-9">
        {{ t('page_subtitle') }}
    </p>
    <MfaAuthenticationForm class="mb-8" />
</template>
