<script lang="ts" setup>
import { VTextField } from 'vuetify/components';
import { computed, ref } from 'vue';
import { useTemplateRef } from 'vue';

const props = defineProps<{ modelValue: string | undefined }>();
const emit = defineEmits<{ (e: 'update:modelValue', value: string): void }>();

const value = computed({
    get() {
        return props.modelValue || '';
    },
    set(value: string) {
        emit('update:modelValue', value);
    },
});

const isShowingPassword = ref(false);
const textField = useTemplateRef<VTextField>('textField');

const focus = () => {
    textField?.value?.focus();
};

const clear = () => {
    textField?.value?.reset();
};

defineExpose({
    clear,
    focus,
});
</script>

<template>
    <v-text-field
        v-bind="$attrs"
        ref="textField"
        v-model="value"
        :type="isShowingPassword ? 'text' : 'password'"
        :append-inner-icon="isShowingPassword ? '$hide' : '$view'"
        :clearable="false"
        @click:append-inner="isShowingPassword = !isShowingPassword"
    ></v-text-field>
</template>
