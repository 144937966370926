import { useAuthStore } from '@/stores/auth';
import { apolloClient } from './index';
import REFRESH_ACCESS_TOKEN from '@/graphql/mutations/refresh-access-token';

export class TokenService {
    private static tokenPromise: Promise<string | void> | null = null;

    static getNewToken() {
        return this.tokenPromise || this.requestRefreshToken();
    }

    private static requestRefreshToken() {
        this.tokenPromise = apolloClient
            .mutate({ mutation: REFRESH_ACCESS_TOKEN })
            .then(({ data }) => {
                const { token } = data?.refreshAccessToken ?? {};

                if (!token) {
                    throw Error('Unauthorized');
                }

                localStorage.setItem('token', token);

                return token;
            })
            .catch(() => {
                const authStore = useAuthStore();
                authStore.logout();
            })
            .finally(() => {
                this.tokenPromise = null;
            });

        return this.tokenPromise;
    }
}
