<script lang="ts" setup>
import { VBtn } from 'vuetify/components';
import { useI18n } from 'vue-i18n';
import PasswordResetForm from '@/components/PasswordResetForm.vue';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { RouteName } from '@/router';
import { useAuthStore } from '@/stores/auth';

const route = useRoute();

const isResetRoute = route.name === RouteName['account.reset-password'];

const { t } = useI18n({
    messages: {
        nl: {
            page_title: isResetRoute
                ? 'Nieuw wachtwoord aanmaken'
                : 'Wachtwoord aanmaken',
            page_title_done: isResetRoute
                ? 'Nieuw wachtwoord aangemaakt'
                : 'Wachtwoord aangemaakt',
            page_subtitle: isResetRoute
                ? 'Maak een nieuw wachtwoord aan'
                : 'Maak een wachtwoord aan',
            return_to_login: 'Terug naar inloggen',
        },
        en: {
            page_title: isResetRoute
                ? 'Create new password'
                : 'Create password',
            page_title_done: isResetRoute
                ? 'New password created'
                : 'Password created',
            page_subtitle: isResetRoute
                ? 'Create a new password'
                : 'Create a password',
            return_to_login: 'Return to login',
        },
    },
});

const isDone = ref(false);

const authStore = useAuthStore();
const router = useRouter();

async function handleSubmit(token: string) {
    isDone.value = true;
    await authStore.logInWithToken(token);
    router.push({ name: 'dashboard.home' });
}
</script>

<template>
    <h1 class="text-h1 mb-5">
        {{ !isDone ? t('page_title') : t('page_title_done') }}
    </h1>
    <template v-if="!isDone">
        <p class="text-label-medium text-outline mb-9">
            {{ t('page_subtitle') }}
        </p>
        <PasswordResetForm @submitted="handleSubmit" />
    </template>
    <v-btn variant="outlined" to="/account/login">{{
        t('return_to_login')
    }}</v-btn>
</template>
