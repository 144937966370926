import { useNotificationStore } from '@/stores/notification';
import * as Sentry from '@sentry/vue';
import { useI18n } from 'vue-i18n';

export function useErrorHandler() {
    const { t } = useI18n();

    const notificationStore = useNotificationStore();

    /**
     * @param err The error passed to Sentry
     * @param message Message to appear in the toast
     * @param quiet Don't trigger toast popup
     */
    const handleError = (err: unknown, message?: string, quiet?: boolean) => {
        if (!quiet) {
            notificationStore.add({
                type: 'error',
                message: message ? t(message) || message : t('unknown_error'),
            });
        }

        Sentry.captureException(err);
    };

    return { handleError };
}
