import { graphql } from '../../gql';

export default graphql(`
    mutation requestPasswordReset($email: String!, $recaptchaToken: String!) {
        requestPasswordReset(
            input: { email: $email, recaptchaToken: $recaptchaToken }
        ) {
            ok
        }
    }
`);
