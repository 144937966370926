<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useI18n } from 'vue-i18n';
import { useLazyQuery } from '@vue/apollo-composable';
import { useRecaptchaProvider, useChallengeV3 } from 'vue-recaptcha';
import { useRouter } from 'vue-router';
import { LOGIN_URL } from '@/graphql/queries';
import { useField, useForm } from 'vee-validate';
import {
    VBtn,
    VForm,
    VContainer,
    VRow,
    VCol,
    VTextField,
} from 'vuetify/components';
import { toTypedSchema } from '@vee-validate/zod';
import * as zod from 'zod';
import { RouteName } from '@/router';
import { ErrorType } from '@/gql/graphql';
import { useErrorHandler } from '@/composables/errors';
import { RouterLink } from 'vue-router';

// Translations
const { t } = useI18n({
    inheritLocale: true,
    messages: {
        nl: {
            log_in: 'Verder',
            email_label: 'E-mail',
            invalid_recaptcha: 'Ongeldige ReCaptcha',
            forgot_password: 'Wachtwoord vergeten',
        },
        en: {
            log_in: 'Proceed',
            email_label: 'Email',
            invalid_recaptcha: 'Invalid ReCaptcha',
            forgot_password: 'Forgot password',
        },
    },
});

useRecaptchaProvider();

const { execute } = useChallengeV3('login');
const { handleError } = useErrorHandler();
const authStore = useAuthStore();
const router = useRouter();

function handleClearEmail() {
    // Vuetify defaults to setting the value to null, which triggers a different error than "required field"
    // Also causes the password reset to autofill 'undefined' instead of empty string
    email.value.value = '';
}

// Form validation
const validationSchema = toTypedSchema(
    zod.object({
        email: zod
            .string()
            .min(1, t('error.required_field'))
            .email(t('error.invalid_email')),
    })
);

const { handleSubmit } = useForm({
    validationSchema,
});

const email = useField<string | undefined>('email');
const recaptchaToken = ref('');

const variables = computed(() => ({
    email: email.value.value,
    recaptchaToken: recaptchaToken.value,
}));

const {
    load: loadLoginUrl,
    onResult: onLoginUrlResult,
    loading: loginUrlLoading,
    onError: onLoginUrlError,
} = useLazyQuery(LOGIN_URL, variables, {
    fetchPolicy: 'no-cache',
});

const onSubmit = handleSubmit(async () => {
    try {
        const recaptchaTokenResponse = await execute();
        recaptchaToken.value = recaptchaTokenResponse;
        await loadLoginUrl();
    } catch (err) {
        console.log(err);
    }
});

onLoginUrlResult((response) => {
    if (response.data.loginUrl.url) {
        window.location.href = response.data.loginUrl.url;
        return;
    }

    authStore.setCredentials({
        email: email.value.value,
        password: '',
    });

    router.push({
        name: RouteName['account.password'],
        query: { email: email.value.value },
    });
});

onLoginUrlError(({ graphQLErrors }) => {
    graphQLErrors.forEach((err) => {
        if (err.extensions.type === ErrorType.INVALID_RECAPTCHA) {
            handleError(new Error(t('invalid_recaptcha')));
            return;
        }
    });
});
</script>

<template>
    <v-form @submit.prevent="onSubmit">
        <v-container class="pa-0">
            <v-row no-gutters align-content="stretch">
                <v-col cols="12">
                    <v-text-field
                        v-model="email.value.value"
                        :label="t('email_label')"
                        :placeholder="t('email_label')"
                        :error-messages="email.errorMessage.value"
                        autofocus
                        type="email"
                        name="email"
                        hide-details="auto"
                        class="mb-4"
                        @click:clear="handleClearEmail"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" align="right" class="mb-8">
                    <router-link
                        variant="text"
                        :to="{ name: RouteName['account.forgot-password'] }"
                        class="text-primary-10 text-body-small"
                    >
                        {{ t('forgot_password') }}
                    </router-link>
                </v-col>
                <v-col>
                    <v-btn
                        :loading="loginUrlLoading"
                        type="submit"
                        color="primary"
                        block
                        >{{ t('log_in') }}</v-btn
                    >
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>
